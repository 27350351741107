import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import axios from 'axios';
import * as APJTool from '../../store/JSTool';
import country from '../../store/country.json';

export default function OneLink_sea_faq() {

    React.useEffect(() => {
        window.location.href = "https://aplusjapan.aihelp.net/webchatv4/#/appKey/APLUSJAPAN_app_7b3411c282bb41768d3a95f14d5c4e4f/domain/aplusjapan.aihelp.net/appId/aplusjapan_platform_3df6a864f3904b475223012dbd7a7cef?entranceId=E001&language=en";
    });

    return (
        <Stack>

        </Stack>
    );
}

