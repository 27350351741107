import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';
import * as JSTool from '../store/JSTool';
import { getImgPath } from '../store/JSTool';
import $, { data } from 'jquery';
import TitleView from '../component/TitleView';
import * as rdd from 'react-device-detect';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';
import { Autoplay, EffectFade } from 'swiper/modules';
import * as APJHttp from '../store/APJHttp';
import { settingActions, settingObj } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';


export default function Page2(props) {
    const [swp, setSwp] = React.useState(null);
    const [currentItem, setCurrentItem] = React.useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    let resizeTimer;
    const [twitterKey, setTwitterKey] = React.useState('');

    React.useEffect(() => {
        requestNewsList();

        changeSize();
        $(window).on("resize", changeSize);
    }, []);

    function changeSize() {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            setTwitterKey(Math.random());
        }, 300);
    }

    function requestNewsList() {
        APJHttp.getNewsLatest(50, (data) => {
            dispatch(settingActions.setNewsList(data));
            setCurrentItem(data[0]);
        }, (code, msg) => {
            dispatch(settingActions.showToast(`code ${code}: ${msg}`));
        });
    }

    function leftTap() {
        if (swp) {
            swp.slidePrev();
        }
    }

    function rightTap() {
        if (swp) {
            swp.slideNext();
        }
    }

    function indexChanged() {
        if (swp && settingData.newsList != null) {
            setCurrentItem(settingData.newsList[swp.realIndex]);
        }
    }

    function newsTap() {
        if (swp && settingData.newsList != null) {
            dispatch(settingActions.showNewsDetailIndex(swp.realIndex));
        }
    }

    return (
        <Stack
            id={'page2'}
            sx={{
                position: 'relative',
                width: 1,
                overflow: 'hidden',
                backgroundSize: settingData.isMobile ? 'cover' : '100% 100%',
                backgroundImage: settingData.isMobile ? `url(${getImgPath('bg2_4_mb.png')})` : `url(${getImgPath('bg2_4.png')})`,
                alignItems: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center'
            }}>

            <Box sx={{
                width: 1,
                height: '150px',
                background: 'linear-gradient(#000000dd , #00000000)',
                position: 'absolute',
                top: 0,
            }} />
            <TitleView title={'NEWS'} anchorId={props.anchorId} mt={settingData.isMobile ? 3 : 5} />

            <Stack sx={{
                width: 1,
                flexDirection: settingData.isMobile ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
                mt: settingData.isMobile ? -2 : 3,
                pb: settingData.isMobile ? 1 : 0,
            }}>

                {/* 左边 */}
                <Stack sx={{
                    alignItems: 'center',
                    width: '700rem',
                    height: settingData.isMobile ? '695rem' : '600rem',
                    backgroundSize: '100% 100%',
                    backgroundImage: `url(${getImgPath('news_big_bg.png')})`,
                }}>
                    <Box
                        component={'img'}
                        src={getImgPath('news_up2.png')}
                        sx={{
                            mt: settingData.isMobile ? (rdd.isIPad13 ? 15 : 7) : 10,
                            height: '42rem'
                        }} />

                    <Typography sx={{
                        width: 1,
                        textAlign: 'center',
                        fontSize: '23rem',
                        color: 'black',
                        fontFamily: window.MyFont
                    }}>
                        LATEST
                    </Typography>

                    <Stack
                        onClick={newsTap}
                        sx={{
                            bgcolor: 'white',
                            flexDirection: 'row',
                            minWidth: settingData.isMobile ? 0.6 : 0.56,
                            borderRadius: '23rem',
                            minHeight: '46rem',
                            alignItems: 'center',
                            px: settingData.isMobile ? 1 : 3,
                            mb: 0.5,
                            cursor: 'pointer',
                        }}>
                        <Typography sx={{
                            fontSize: 13,
                            fontWeight: 'bold',
                        }}>
                            {currentItem && currentItem.display_time}
                        </Typography>

                        <Typography
                            sx={{
                                fontSize: settingData.isMobile ? 10 : 13,
                                fontWeight: 'bold',
                                width: settingData.isMobile ? '270rem' : '330rem',
                                wordBreak: 'break-word',
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                                pl: 1.5,

                                // 只显示2行
                                display: '-webkit-box',
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                            {currentItem && currentItem.title}
                        </Typography>
                    </Stack>
                    <Box
                        component={'img'}
                        src={getImgPath('news_bottom2.png')}
                        sx={{
                            height: '19rem'
                        }} />

                    {/* 滚动图片 */}
                    <Stack sx={{
                        marginTop: '20rem',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>

                        <Box
                            onClick={leftTap}
                            component={'img'}
                            src={JSTool.getImgPath('swip_left.png')}
                            sx={{
                                height: '30rem',
                                objectFit: 'cover',
                                cursor: 'pointer',
                                transitionDuration: '0.3s',
                                '&:hover': {
                                    transform: 'scale(1.3)',
                                }
                            }} />

                        <Swiper style={{
                            width: '440rem',
                            height: settingData.isMobile ? '260rem' : '240rem',
                        }}
                            resistanceRatio={0}
                            loop={true}
                            modules={[Autoplay]}
                            slidesPerView={1}
                            centeredSlides={true}
                            slideToClickedSlide={true}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}

                            onSlideChangeTransitionEnd={(sp) => {
                                indexChanged();
                            }}

                            onSwiper={(swiper) => {
                                setSwp(swiper);
                                swiper.slideTo(0);
                            }}>
                            {
                                settingData.newsList && settingData.newsList.map((item, index) => {
                                    return <SwiperSlide key={index}> <NewsSwipItem item={item} newsTap={newsTap} /> </SwiperSlide>
                                })
                            }

                        </Swiper>
                        <Box
                            onClick={rightTap}
                            component={'img'}
                            src={JSTool.getImgPath('swip_right.png')}
                            sx={{
                                mr: 1,
                                height: '30rem',
                                objectFit: 'cover',
                                cursor: 'pointer',
                                transitionDuration: '0.3s',
                                '&:hover': {
                                    transform: 'scale(1.3)',
                                }
                            }} />
                    </Stack>

                </Stack>

                {/* 右边 */}
                <Stack
                    id='twitter_ct'
                    key={twitterKey}
                    sx={{
                        display: settingData.isMobile || settingData.isSea ? 'none' : 'flex',
                        mt: 2,
                        width: '500rem',
                        height: '450rem',
                        mb: 3,
                        bgcolor: '#BB9455',
                        border: 7,
                        borderColor: '#BB9455',
                        position: 'relative'
                    }} >

                    <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="LoNGameOfficial"
                        theme='light'
                        options={{ height: $('#twitter_ct').height() }}
                    />

                    <Stack sx={{
                        position: 'absolute',
                        width: 1,
                        height: settingData.isMobile ? '55rem' : '45rem',
                        bottom: 0,
                        bgcolor: 'black',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}>

                        <Box component={'img'}
                            src={JSTool.getImgPath('X.png')}
                            sx={{
                                height: 0.5,
                                ml: 5,
                            }} />

                        <Typography sx={{
                            flex: 1,
                            fontWeight: 'bold',
                            fontSize: '15rem',
                            color: 'white',
                            ml: 2,
                        }}>
                            FOLLOW US
                        </Typography>

                        <Typography
                            onClick={() => {
                                JSTool.twitterTap();
                            }}
                            sx={{
                                mr: 4,
                                fontWeight: 'bold',
                                fontSize: '14rem',
                                color: 'white',
                                cursor: 'pointer'
                            }}>
                            @LoNGameOfficial
                        </Typography>
                    </Stack>
                </Stack>

                <Box
                    onClick={() => {
                        JSTool.twitterTap();
                    }}
                    src={JSTool.getImgPath('tt_mb.png')}
                    component={'img'}
                    sx={{
                        display: settingData.isMobile && !settingData.isSea ? 'flex' : 'none',
                        mt: 2,
                        width: settingData.isMobile ? '580rem' : '500rem',
                        height: settingData.isMobile ? '580rem' : '450rem',
                        mb: 3,
                        position: 'relative',
                        cursor: 'pointer'
                    }} />

            </Stack>

            {/* 底部渐变色 */}
            <Box sx={{
                display: settingData.isMobile ? 'flex' : 'none',
                width: 1,
                height: '30px',
                background: 'linear-gradient(#080B0D00 , #010000)',
                zIndex: 1,
                bottom: 0,
                position: 'absolute',
            }} />

        </Stack>
    );
}

function NewsSwipItem(props) {
    return (
        <Stack sx={{
            width: 1,
            height: 1,
        }}>
            <Box
                onClick={props.newsTap}
                component={'img'}
                src={JSTool.getImgPath(props.item.image_url)}
                sx={{
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                    cursor: 'pointer',
                }}
            />
        </Stack>
    );
}