import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import axios from 'axios';
import * as APJTool from '../../store/JSTool';
import country from '../../store/country.json';

export default function OneLink_sea() {

    React.useEffect(() => {
        window.location.href = "https://apjoverlordsea.onelink.me/bEQy/80z7mjxr";
    });

    return (
        <Stack>

        </Stack>
    );
}

